<template>
  <v-card :elevation="0">
    <v-card-title>{{ $t("m.order.title") }}</v-card-title>
    <v-card-text>
      <v-col>
        <v-row>
          <v-col>
            <span v-html="$t('m.order.text')" />
          </v-col>
        </v-row>
        <v-row v-if="ordered" justify="center">
          <v-alert type="info" outlined>{{ $t("m.order.ordered") }}</v-alert>
        </v-row>
        <v-row v-else justify="center">
          <v-btn text color="primary" @click="order">{{
            $t("m.order.doOrder")
          }}</v-btn>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { orderQRCodes } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  data: () => ({
    ordered: false
  }),
  methods: {
    async order () {
      this.ordered = true
      await orderQRCodes()
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_ORDER_QR_CODES)
  }
}
</script>
